// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-games-bowling-3-d-js": () => import("./../../../src/pages/games/bowling3d.js" /* webpackChunkName: "component---src-pages-games-bowling-3-d-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-tools-calc-plus-js": () => import("./../../../src/pages/tools/calc-plus.js" /* webpackChunkName: "component---src-pages-tools-calc-plus-js" */),
  "component---src-pages-tools-prime-factorization-js": () => import("./../../../src/pages/tools/prime-factorization.js" /* webpackChunkName: "component---src-pages-tools-prime-factorization-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

